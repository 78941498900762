interface StoreIDS {
  [index: string]: {
    [index: string]: string;
  };
}

const storeIds: StoreIDS = {
  xxxlutz: {
    at: 'XI',
    de: 'XK',
    cz: 'XZ',
    se: 'XX',
    hr: 'XL',
    si: 'HY',
    ch: 'YJ',
    rs: 'JN',
    sk: 'M7',
    hu: 'HO',
    ro: 'ZO',
  },
  moemax: {
    at: 'YP',
    de: 'YH',
    si: 'CS',
    hu: 'CH',
    ro: 'AC',
    hr: 'DG',
    pl: 'WF',
    ch: '66',
    rs: 'DE',
  },
  moebelix: { at: 'YI', sk: 'TK', cz: 'XY', hu: 'YL' },
};

export const getStoreId = (railName: string, code: string) => storeIds[railName]?.[code];
