import { countryContextData } from '@xxxlgroup/xxxl-library-datetime';

import { getRoutes } from './routesDir';
import { getStoreId } from './countryStores';
import { getShopUrl, getShopName } from './countryLookup';

const getCountryContextData = (
  languageCode = CONFIG.COUNTRY,
  rail = CONFIG.RAIL,
  railName = CONFIG.RAIL_NAME,
) => ({
  ...countryContextData(languageCode, railName, rail),
  routes: getRoutes(languageCode, rail),
  shopName: getShopName(rail, languageCode),
  shopUrl: getShopUrl(rail, languageCode),
  store: getStoreId(railName, languageCode),
});

export default getCountryContextData;
