import { ApolloLink } from '@apollo/client';
import { fireOnUserChangeEvent } from 'modules/customer-authentication/AuthContext';

/**
 * checkAuthStatusLink will check whether there is an incoming `x-poseidon-is-user-changed` header,
 * and if that's the case it will pass it to the notification function provided by the
 * customer-authentication module.
 *
 * It will not affect the GraphQL operation at all in any case.
 *
 * This comes from https://jira.xxxlgroup.com/browse/XXXL-33590
 */

const checkAuthStatusLink = new ApolloLink((operation, forward) =>
  forward(operation).map((response) => {
    const context = operation.getContext();
    const {
      response: { headers },
    } = context;

    if (headers) {
      const userChanged = headers.get('x-poseidon-is-user-changed');
      const invalidToken = headers.get('x-poseidon-invalid-token');

      if (userChanged || invalidToken) {
        fireOnUserChangeEvent(!!invalidToken);
      }
    }

    return response;
  }),
);

export default checkAuthStatusLink;
