import { SEARCHTERM_QUERY } from 'components/Header/components/SearchBar/SearchBar.query';
import { searchBarDefaults } from 'components/Header/components/SearchBar/SearchBar.variables';
import { smartEditDefaults } from 'cms/WithSmartEdit/WithSmartEdit.resolver';
import { CMSCONTENT_PAGE_QUERY } from 'components/CmsContent/CmsContent.query';

const writeDefaultsToCache = (cacheToUse) => {
  cacheToUse.writeQuery({ query: SEARCHTERM_QUERY, data: searchBarDefaults });
  cacheToUse.writeQuery({
    data: smartEditDefaults,
    query: CMSCONTENT_PAGE_QUERY,
  });
};

export default writeDefaultsToCache;
