import localeDe from 'dayjs/locale/de';
import localeDeAt from 'dayjs/locale/de-at';
import localeSv from 'dayjs/locale/sv';
import localeSr from 'dayjs/locale/sr';
import localePl from 'dayjs/locale/pl';
import localeCs from 'dayjs/locale/cs';
import localeHr from 'dayjs/locale/hr';
import localeHu from 'dayjs/locale/hu';
import localeRo from 'dayjs/locale/ro';
import localeSl from 'dayjs/locale/sl';
import localeSk from 'dayjs/locale/sk';

const kunaEuroConversionRate = 7.5345;

const currenciesLookupTable = {
  EUR: {
    locale: 'de-AT',
    options: {
      style: 'currency',
      currencyDisplay: 'symbol',
      currency: 'EUR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    zeroFractionWithDash: true,
  },
  SEK: {
    locale: 'sv-SE',
    options: {
      style: 'decimal',
      currency: 'SEK',
      maximumFractionDigits: 0,
    },
    custom: {
      postfix: ':-',
      noEmptySpaceBeforePostfix: true,
    },
  },
  RSD: {
    locale: 'sr-RS',
    options: {
      style: 'currency',
      currencyDisplay: 'symbol',
      currency: 'RSD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    zeroFractionWithDash: true,
  },
  PLN: {
    locale: 'pl-PL',
    options: {
      style: 'currency',
      currencyDisplay: 'symbol',
      currency: 'PLN',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    zeroFractionWithDash: true,
  },
  CZK: {
    locale: 'cs-CZ',
    options: {
      style: 'currency',
      currencyDisplay: 'symbol',
      currency: 'CZK',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    zeroFractionWithDash: true,
  },
  CHF: {
    locale: 'de-CH',
    options: {
      style: 'currency',
      currencyDisplay: 'symbol',
      currency: 'CHF',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    zeroFractionWithDash: true,
  },
  HRK: {
    locale: 'hr-HR',
    options: {
      style: 'currency',
      currencyDisplay: 'symbol',
      currency: 'HRK',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    zeroFractionWithDash: false,
    custom: {
      postfix: ' kn',
    },
    conversionRate: kunaEuroConversionRate,
  },
  // Using 'de-AT' in order to get the proper formatting of the EUR currency,
  // to place EUR symbol in front of the value, as in the Austrian shop.
  // This feature is still not available for Croatia, as it is using HRK.
  // Should be updated once 'hr-HR' is properly defined for EUR.
  HREUR: {
    locale: 'de-AT',
    options: {
      style: 'currency',
      currencyDisplay: 'symbol',
      currency: 'EUR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    zeroFractionWithDash: true,
    conversionRate: kunaEuroConversionRate,
  },
  HUF: {
    locale: 'hu-HU',
    options: {
      style: 'currency',
      currencyDisplay: 'symbol',
      currency: 'HUF',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    zeroFractionWithDash: true,
  },
  RON: {
    locale: 'ro-RO',
    options: {
      style: 'currency',
      currencyDisplay: 'symbol',
      currency: 'RON',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    zeroFractionWithDash: true,
  },
};

export const languageLookupTable = {
  at: {
    code: 'de',
    region: 'de-AT',
    locale: 'de-at',
    currency: 'EUR',
    currencyFormat: currenciesLookupTable.EUR,
    englishName: 'German',
    name: 'Deutsch',
    phone: {
      areaCode: '43',
      domesticPrefix: '0',
    },
    postalCode: {
      regex: /^\d{4}$/i,
      template: 'dddd',
    },
    dayjsLocale: {
      formats: {
        ...localeDeAt.formats,
        ll: 'DD.MM.',
      },
    },
    dateFormatInput: localeDeAt.formats.L,
    dateFormatMonthYear: 'MM.YYYY',
    timeZone: 'Europe/Vienna',
  },
  de: {
    code: 'de',
    region: 'de-DE',
    locale: 'de',
    currency: 'EUR',
    currencyFormat: currenciesLookupTable.EUR,
    englishName: 'German',
    name: 'Deutsch',
    phone: {
      areaCode: '49',
      domesticPrefix: '0',
    },
    postalCode: {
      regex: /^\d{5}$/i,
      template: 'ddddd',
    },
    dayjsLocale: {
      formats: {
        ...localeDe.formats,
        ll: 'DD.MM.',
      },
    },
    dateFormatInput: localeDe.formats.L,
    dateFormatMonthYear: 'MM.YYYY',
    timeZone: 'Europe/Berlin',
  },
  se: {
    code: 'sv',
    region: 'sv-SE',
    locale: 'sv',
    currency: 'SEK',
    currencyFormat: currenciesLookupTable.SEK,
    englishName: 'Swedish',
    name: 'Svenska',
    phone: {
      areaCode: '46',
      domesticPrefix: '0',
    },
    postalCode: {
      regex: /^\d{3}\s\d{2}$/i,
      template: 'ddd dd',
    },
    dayjsLocale: {
      formats: {
        ...localeSv.formats,
        ll: 'MM-DD',
      },
    },
    dateFormatInput: localeSv.formats.L,
    dateFormatMonthYear: 'MM.YYYY',
    timeZone: 'Europe/Stockholm',
  },
  rs: {
    code: 'rs',
    region: 'sr-RS',
    locale: 'sr',
    currency: 'RSD',
    currencyFormat: currenciesLookupTable.RSD,
    englishName: 'Serbian',
    name: 'Srpski',
    phone: {
      areaCode: '381',
      domesticPrefix: '0',
    },
    postalCode: {
      regex: /^\d{5}$/i,
      template: 'ddddd',
    },
    dayjsLocale: {
      formats: {
        ...localeSr.formats,
        ll: 'DD.MM.',
      },
    },
    dateFormatInput: 'DD.MM.YYYY',
    dateFormatMonthYear: 'MM.YYYY',
    timeZone: 'Europe/Belgrade',
  },
  pl: {
    code: 'pl',
    region: 'pl-PL',
    locale: 'pl',
    currency: 'PLN',
    currencyFormat: currenciesLookupTable.PLN,
    englishName: 'Polish',
    name: 'Polski',
    phone: {
      areaCode: '48',
      domesticPrefix: '0',
    },
    postalCode: {
      regex: /^\d{2}-\d{3}$/i,
      template: 'dd-ddd',
    },
    dayjsLocale: {
      formats: {
        ...localePl.formats,
        ll: 'DD.MM.',
      },
    },
    dateFormatInput: localePl.formats.L,
    dateFormatMonthYear: 'MM.YYYY',
    timeZone: 'Europe/Warsaw',
  },
  cz: {
    code: 'cs',
    region: 'cs-CZ',
    locale: 'cs',
    currency: 'CZK',
    currencyFormat: currenciesLookupTable.CZK,
    englishName: 'Czech',
    name: 'Čeština',
    phone: {
      areaCode: '420',
      domesticPrefix: '0',
    },
    postalCode: {
      regex: /^\d{3}\s\d{2}$/i,
      template: 'ddd dd',
    },
    dayjsLocale: {
      formats: {
        ...localeCs.formats,
        ll: 'DD.MM.',
      },
    },
    dateFormatInput: localeCs.formats.L,
    dateFormatMonthYear: 'MM.YYYY',
    timeZone: 'Europe/Prague',
  },
  ch: {
    code: 'ch',
    region: 'de-AT',
    locale: 'de',
    currency: 'CHF',
    currencyFormat: currenciesLookupTable.CHF,
    englishName: 'Switzerland',
    name: 'Schweiz',
    phone: {
      areaCode: '41',
      domesticPrefix: '0',
    },
    postalCode: {
      regex: /^\d{4}$/i,
      template: 'dddd',
    },
    dayjsLocale: {
      formats: {
        ...localeDe.formats,
        ll: 'DD.MM.',
      },
    },
    dateFormatInput: localeDe.formats.L,
    dateFormatMonthYear: 'MM.YYYY',
    timeZone: 'Europe/Zurich',
  },
  hr: {
    code: 'hr',
    region: 'hr-HR',
    locale: 'hr',
    currency: 'HRK',
    currencyFormat: currenciesLookupTable.HREUR,
    englishName: 'Croatian',
    name: 'Hrvatski',
    phone: {
      areaCode: '385',
      domesticPrefix: '0',
    },
    postalCode: {
      regex: /^\d{5}$/i,
      template: 'ddddd',
    },
    dayjsLocale: {
      formats: {
        ...localeHr.formats,
        ll: 'DD.MM.',
      },
    },
    dateFormatInput: localeHr.formats.L,
    dateFormatMonthYear: 'MM.YYYY',
    timeZone: 'Europe/Zagreb',
  },
  hu: {
    code: 'hu',
    region: 'hu-HU',
    locale: 'hu',
    currency: 'HUF',
    currencyFormat: currenciesLookupTable.HUF,
    englishName: 'Hungarian',
    name: 'Magyar',
    phone: {
      areaCode: '36',
      domesticPrefix: '06',
    },
    postalCode: {
      regex: /^\d{4}$/i,
      template: 'dddd',
    },
    dayjsLocale: {
      formats: {
        ...localeHu.formats,
        ll: 'MM.DD.',
      },
    },
    dateFormatInput: 'YYYY.MM.DD',
    dateFormatMonthYear: 'YYYY.MM',
    timeZone: 'Europe/Budapest',
  },
  ro: {
    code: 'ro',
    region: 'ro-RO',
    locale: 'ro',
    currency: 'RON',
    currencyFormat: currenciesLookupTable.RON,
    englishName: 'Romanian',
    name: 'Română',
    phone: {
      areaCode: '40',
      domesticPrefix: '0',
    },
    postalCode: {
      regex: /^\d{6}$/i,
      template: 'dddddd',
    },
    dayjsLocale: {
      formats: {
        ...localeRo.formats,
        ll: 'DD.MM.',
      },
    },
    dateFormatInput: localeRo.formats.L,
    dateFormatMonthYear: 'MM.YYYY',
    timeZone: 'Europe/Bucharest',
  },
  si: {
    code: 'sl',
    region: 'sl-SI',
    locale: 'sl',
    currency: 'EUR',
    currencyFormat: currenciesLookupTable.EUR,
    englishName: 'Slovenian',
    name: 'Slovenščina',
    phone: {
      areaCode: '386',
      domesticPrefix: '0',
    },
    postalCode: {
      regex: /^\d{4}$/i,
      template: 'dddd',
    },
    dayjsLocale: {
      formats: {
        ...localeSl.formats,
        L: 'D.M.YYYY',
        ll: 'DD.MM.',
      },
    },
    dateFormatInput: localeSl.formats.L,
    dateFormatMonthYear: 'MM.YYYY',
    timeZone: 'Europe/Ljubljana',
  },
  sk: {
    code: 'sk',
    region: 'sk-SK',
    locale: 'sk',
    currency: 'EUR',
    currencyFormat: currenciesLookupTable.EUR,
    englishName: 'Slovak',
    name: 'Slovenský',
    phone: {
      areaCode: '421',
      domesticPrefix: '0',
    },
    postalCode: {
      regex: /^\d{3}\s\d{2}$/i,
      template: 'ddd dd',
    },
    dayjsLocale: {
      formats: {
        ...localeSk.formats,
        ll: 'DD.MM.',
      },
    },
    dateFormatInput: localeSk.formats.L,
    dateFormatMonthYear: 'MM.YYYY',
    timeZone: 'Europe/Bratislava',
  },
};
