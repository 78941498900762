import { apiPath, baseHeaders } from 'apollo/config';
import createCSRTerminatingLink from 'apollo/links/createCSRTerminatingLink';
import persistedQueryLink from 'apollo/links/persistedQueryLink';
import createErrorLink from 'apollo/links/createErrorLink';
import csrfLink from 'apollo/links/csrfLink';
import checkAuthStatusLink from 'apollo/links/checkAuthStatusLink';
import trackingMiddlewareLink from 'apollo/links/trackingMiddlewareLink';

const createApolloClientLink = (apiUrl, languageCode, logger) =>
  createCSRTerminatingLink(
    [
      checkAuthStatusLink,
      createErrorLink(apiUrl, logger),
      csrfLink(apiUrl),
      trackingMiddlewareLink.split(
        (operation) => operation.getContext().batch || false,
        persistedQueryLink(false),
        persistedQueryLink(true),
      ),
    ].filter(Boolean),
    apiPath,
    CONFIG.IS_PROD ? {} : { ...baseHeaders, 'X-LANGUAGE': languageCode },
  );

export default createApolloClientLink;
