export const getFetchUri = (uri, options) => {
  const { body } = options;
  let modifiedUri = uri;

  if (body) {
    const parsedBody = JSON.parse(body);
    const { operationName } = Array.isArray(parsedBody) ? parsedBody[0] : parsedBody;
    modifiedUri = `${uri}?operationName=${operationName}`;
  }

  return modifiedUri;
};

export const fetchUpload = (uri, options) => fetch(`${uri}?operationName=upload`, options);
