import { routesLangType } from 'Providers/IntlProvider/routesDir/routesDir.types';
import { routesDefault } from './common';
import { routesAT } from './at';
import { routesDE } from './de';
import { routesRS } from './rs';
import { routesSE } from './se';
import { routesPL } from './pl';
import { routesCZ } from './cz';
import { routesCH } from './ch';
import { routesHR } from './hr';
import { routesHU } from './hu';
import { routesRO } from './ro';
import { routesSI } from './si';
import { routesSK } from './sk';
import { routeRailLangSpecific } from './railEnvSpecific';

const map: routesLangType = {
  at: { ...routesDefault, ...routesAT },
  de: { ...routesDefault, ...routesDE },
  rs: { ...routesDefault, ...routesRS },
  se: { ...routesDefault, ...routesSE },
  pl: { ...routesDefault, ...routesPL },
  cz: { ...routesDefault, ...routesCZ },
  ch: { ...routesDefault, ...routesCH },
  hr: { ...routesDefault, ...routesHR },
  hu: { ...routesDefault, ...routesHU },
  ro: { ...routesDefault, ...routesRO },
  si: { ...routesDefault, ...routesSI },
  sk: { ...routesDefault, ...routesSK },
};

export function getRoutes(lang: string, rail: string) {
  // We need to keep 'any' here because the object is too complicated to make TS access it properly.
  const routesByRailEnv = (routeRailLangSpecific as any)?.[rail]?.[lang] ?? {};
  return { ...map[lang], ...routesByRailEnv };
}
