const storeOverviewDefault = 'storeoverview';

export const routeRailLangSpecific = {
  lz: {
    rs: {
      eTrustmark: '/trust',
    },
    de: {
      kitchenStyleFinder: '/kuechen-stilfinder',
    },
  },
  mmx: {
    at: {
      customerCardInfo: {
        name: '/c/unserebonuscard',
      },
      storeOverview: {
        breadcrumbLink: '/filialen-at',
        name: storeOverviewDefault,
        root: `/(${storeOverviewDefault}|filialen-at)`,
      },
    },
    de: {
      customerCardInfo: {
        name: '/c/unserebonuscard',
      },
      storeOverview: {
        breadcrumbLink: '/filialen-de',
        name: storeOverviewDefault,
        root: `/(${storeOverviewDefault}|filialen-de)`,
      },
    },
    ch: {
      customerCardInfo: {
        name: '/c/unserebonuscard',
      },
      storeOverview: {
        breadcrumbLink: '/filialen-ch',
        name: storeOverviewDefault,
        root: `/(${storeOverviewDefault}|filialen-ch)`,
      },
    },
    hr: {
      customerCardInfo: {
        name: '/c/bonuscard',
      },
      storeOverview: {
        breadcrumbLink: '/moemax_poslovnice',
        name: storeOverviewDefault,
        root: `/(${storeOverviewDefault}|moemax_poslovnice)`,
      },
    },
    hu: {
      storeOverview: {
        breadcrumbLink: '/aruhazak',
        name: storeOverviewDefault,
        root: `/(${storeOverviewDefault}|aruhazak)`,
      },
    },
    si: {
      customerCardInfo: {
        name: '/c/kartica-ugodnosti',
      },
      storeOverview: {
        breadcrumbLink: '/poslovalnice',
        name: storeOverviewDefault,
        root: `/(${storeOverviewDefault}|poslovalnice)`,
      },
    },
    pl: {
      storeOverview: {
        breadcrumbLink: '/sklep',
        name: storeOverviewDefault,
        root: `/(${storeOverviewDefault}|sklep)`,
      },
    },
    ro: {
      storeOverview: {
        breadcrumbLink: '/magazine',
        name: storeOverviewDefault,
        root: `/(${storeOverviewDefault}|magazine)`,
      },
    },
    rs: {
      comingSoon: {
        root: '/',
        agb: '/c/OUP',
        legalNotice: '/c/impressum',
        optIn: '/c/newsletter-optinconfirm',
        optOut: '/c/newsletter-optout',
      },
      legal: {
        privacy: '/c/politika_privatnosti',
      },
    },
  },
  mbx: {
    at: {
      customerCardInfo: {
        name: '/c/vorteilskarte',
      },
      storeOverview: {
        breadcrumbLink: '/filialen',
        name: storeOverviewDefault,
        root: `/(${storeOverviewDefault}|filialen)`,
      },
    },
    cz: {
      customerCardInfo: {
        name: '/c/karta-vyhod',
      },
      storeOverview: {
        breadcrumbLink: '/prodejny',
        name: storeOverviewDefault,
        root: `/(${storeOverviewDefault}|prodejny)`,
      },
    },
    sk: {
      customerCardInfo: {
        name: '/c/karta-vyhod',
      },
      storeOverview: {
        breadcrumbLink: '/predajne',
        name: storeOverviewDefault,
        root: `/(${storeOverviewDefault}|predajne)`,
      },
    },
    hu: {
      customerCardInfo: {
        name: '/c/elonykartya',
      },
      storeOverview: {
        breadcrumbLink: '/aruhazak',
        name: storeOverviewDefault,
        root: `/(${storeOverviewDefault}|aruhazak)`,
      },
    },
  },
  poco: {
    de: {
      // temporary override to redirect for poco contact center until the new footer with configurable paths is in place
      contactcenter: {
        name: 'c/kontakt',
        root: '/c/kontakt',
        confirmation: 'danke',
      },
      contactPages: {
        general: {
          id: 'poco_contact_general',
          url: '/kontakt-allgemeine-anfrage-onlineshop-stationaerer-handel',
          iframe:
            'https://de-usudmz03.poco.de/knowledgecenter/docShow.do?mandatorKey=MANDATOR_USU&callFromKminer=true&entity.GUID=de1c5842-7785-4ddc-85d4-28ge0c30f01d',
          isTokenRequired: true,
          isHeightFixed: true,
        },
        claim: {
          id: 'poco_contact_claim',
          url: '/kontakt-allgemeine-beanstandungsaufnahme',
          iframe:
            'https://de-usudmz03.poco.de/knowledgecenter/docShow.do?mandatorKey=MANDATOR_USU&callFromKminer=true&entity.GUID=992478e1-c0e7-4952-9c7e-9ee230879fgg',
          isTokenRequired: true,
          isHeightFixed: true,
        },
        hq: {
          id: 'poco_contact_hq',
          url: '/kontakt-nachricht-an-die-zentrale',
          iframe:
            'https://de-usudmz03.poco.de/knowledgecenter/docShow.do?mandatorKey=MANDATOR_USU&callFromKminer=true&entity.GUID=b41f8f8d-d17g-4c93-8ggg-957defdg65b0',
          isTokenRequired: true,
          isHeightFixed: true,
        },
        criticism: {
          id: 'poco_contact_criticism',
          url: '/kontakt-lob-kritik-anregungen',
          iframe:
            'https://de-usudmz03.poco.de/knowledgecenter/docShow.do?mandatorKey=MANDATOR_USU&callFromKminer=true&entity.GUID=g150240g-6589-4fe3-9282-e421145c63fg',
          isTokenRequired: true,
          isHeightFixed: true,
        },
        contact: {
          id: 'poco_contact_contact',
          url: '/kontakt-mit-poco-aufnehmen',
          iframe: 'https://kontakt.poco.de/cms/front_content.php?idart=2847',
          isTokenRequired: false,
          isHeightFixed: false,
        },
      },
      storeOverview: {
        breadcrumbLink: '/standorte',
        name: storeOverviewDefault,
        root: `/(${storeOverviewDefault}|standorte)`,
      },
    },
  },
};
