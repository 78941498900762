/* this file has side effects */
import './runtime/polyfills';
import initRuntime from './runtime/initRuntime';

initRuntime();

// just needed for webpack HMR, will be removed in production
// its avoid full page reload when HMR happens
if (import.meta.webpackHot) {
  import.meta.webpackHot.accept();
}
