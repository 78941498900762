const developmentHeaders = {
  'X-RAIL': CONFIG.RAIL_NAME,
  'X-ENV': CONFIG.SERVER_ENV,
};

const productionHeaders = {};

export const baseHeaders = CONFIG.IS_PROD
  ? productionHeaders
  : Object.assign(developmentHeaders, productionHeaders);

export const defaultOptions = {
  mutate: {
    errorPolicy: 'all',
  },
  query: {
    errorPolicy: 'all',
  },
  watchQuery: {
    errorPolicy: 'all',
  },
};

export const baseClientOptions = {
  assumeImmutableResults: true,
  defaultOptions,
};

export const apiPath = '/api/graphql';
