export const routesSK = {
  brochuresPage: {
    root: '/(prospekty|brochures)/:brochureTypeId?',
    name: 'brochures',
  },
  category: {
    name: 'kategorie',
    root: '/(kategorie|categories)/root',
  },
  contactcenter: {
    name: 'kontaktnecentrum',
    root: '/(kontaktnecentrum|contactcenter)',
    confirmation: 'dakujeme',
  },
  customerCardInfo: {
    name: '/c/xxxl-hitkarta',
  },
  orderInformationPage: {
    name: 'stavobjednavky',
    root: '/(orderStatus|stavobjednavky)',
    result: 'order',
    cancel: 'cancel',
  },
  reservationPage: {
    confirmation: '/rezervacia/confirmation',
    form: 'rezervacia/form',
    name: 'rezervacia',
    root: '/(rezervacia|reservation)/:step?',
  },
  searchPage: {
    brands: 'znacky',
    root: ['/(p/|search|s)', '/znacky/:brandUrlCode'],
  },
  storeFinder: '/c/pobocky',
  storeOverview: {
    breadcrumbLink: '/pobocky',
    name: 'storeoverview',
    root: '/(storeoverview|pobocky)',
  },
  subsidiaryPage: {
    root: '/(pobocka|subsidiary)/:SEOSlug/:subsidiaryId',
    name: 'pobocka',
  },
  timendoPage: {
    name: 'terminkonzultacie',
    root: '/(terminkonzultacie|appointment)',
  },
};
