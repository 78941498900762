import { createContext } from 'react';
import {
  ContentSlot,
  DynamicSlotsQuery,
} from 'services/DynamicContentService/DynamicContentService';

export interface DynamicContentContextValue {
  debug: DynamicSlotsQuery['receiveContentSlots']['debug'];
  dismissedSlots: string[];
  dismissSlot: (id: string) => void;
  loading: boolean;
  reloadContent: () => void;
  slots: ContentSlot[];
}

const DynamicContentContext = createContext<DynamicContentContextValue>({
  debug: [],
  dismissedSlots: [],
  dismissSlot: () => {},
  loading: false,
  reloadContent: () => {},
  slots: [],
});

export default DynamicContentContext;
