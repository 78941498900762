export const routesCZ = {
  brochuresPage: {
    root: '/(prospekty|brochures)/:brochureTypeId?',
    name: 'brochures',
  },
  category: {
    name: 'kategorie',
    root: '/(kategorie|categories)/root',
  },
  contactcenter: {
    name: 'kontaktnicentrum',
    root: '/(kontaktnicentrum|contactcenter)',
    confirmation: 'dekujeme',
  },
  customerCardInfo: {
    name: '/c/xxxl-hitkarta',
  },
  orderInformationPage: {
    name: 'stavobjednavky',
    root: '/(orderStatus|stavobjednavky)',
    result: 'order',
    cancel: 'cancel',
  },
  reservationPage: {
    name: 'rezervace',
    form: 'rezervace/form',
    root: '/(rezervace|reservation)/:step?',
    confirmation: '/reservation/confirmation',
  },
  searchPage: {
    brands: 'znacky',
    root: ['/(p/|search|s)', '/znacky/:brandUrlCode'],
  },
  storeFinder: '/c/pobocky',
  storeOverview: {
    breadcrumbLink: '/pobocky',
    name: 'storeoverview',
    root: '/(storeoverview|pobocky)',
  },
  subsidiaryPage: {
    root: '/(pobocka|subsidiary)/:SEOSlug/:subsidiaryId',
    name: 'pobocka',
  },
  timendoPage: {
    name: 'poradenskytermin',
    root: '/(poradenskytermin|appointment)',
  },
};
