export const routesHR = {
  brochuresPage: {
    root: '/(prospekti|brochures)/:brochureTypeId?',
    name: 'brochures',
  },
  cart: '/kosara',
  category: {
    name: 'kategorije',
    root: '/(kategorije|categories)/root',
  },
  contactcenter: {
    name: 'kontaktnicentar',
    root: '/(kontaktnicentar|contactcenter)',
    confirmation: 'hvala',
  },
  customerCardInfo: {
    name: '/c/lesnina-card-pogodnosti',
  },
  orderInformationPage: {
    name: 'statusugovora',
    root: '/(orderStatus|statusugovora)',
    result: 'order',
    cancel: 'cancel',
  },
  reservationPage: {
    name: 'rezervacija',
    form: 'rezervacija/form',
    root: '/(rezervacija|reservation)/:step?',
    confirmation: '/reservation/confirmation',
  },
  searchPage: {
    brands: 'robnemarke',
    root: ['/(p/|search|s)', '/robnemarke/:brandUrlCode'],
  },
  subsidiaryPage: {
    root: '/(poslovnica|subsidiary)/:SEOSlug/:subsidiaryId',
    name: 'poslovnica',
  },
  timendoPage: {
    name: 'terminzaSavjetovanje',
    root: '/(terminzaSavjetovanje|appointment)',
  },
  storeFinder: '/c/poslovne-jedinice',
  storeOverview: {
    breadcrumbLink: '/poslovne-jedinice',
    name: 'storeoverview',
    root: '/(storeoverview|poslovne-jedinice)',
  },
};
