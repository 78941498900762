const lutzName = 'xxxlutz';
const flavourLutzName = 'xxxlesnina';

const moebelixName = 'moebelix';

const moemaxName = 'moemax';
const variantMoemaxName = 'momax';

const pocoName = 'poco';

const shopNameLookup: Record<string, Record<string, string>> = {
  lz: {
    at: lutzName,
    ch: lutzName,
    cz: lutzName,
    de: lutzName,
    hr: flavourLutzName,
    hu: lutzName,
    ro: lutzName,
    rs: flavourLutzName,
    se: lutzName,
    si: flavourLutzName,
    sk: lutzName,
  },
  mbx: {
    at: moebelixName,
    cz: moebelixName,
    hu: moebelixName,
    sk: moebelixName,
  },
  mmx: {
    at: moemaxName,
    ch: moemaxName,
    de: moemaxName,
    hr: moemaxName,
    hu: moemaxName,
    pl: variantMoemaxName,
    ro: moemaxName,
    si: moemaxName,
    rs: moemaxName,
  },
  poco: {
    de: pocoName,
  },
};

export const getShopName = (rail: string, country: string) => shopNameLookup[rail]?.[country];

export const getShopUrl = (rail: string, country: string) =>
  `https://www.${getShopName(rail, country)}.${country}`;
