const possibleTypes = {
  BaseSearchResult: ['Product', 'Campaign'],
  BaseRestriction: [
    'Restriction',
    'ProductQuantityRestriction',
    'ProductGroupRestriction',
    'ProductRestriction',
    'ProductBrandRestriction',
    'ProductConfiguredRestriction',
    'StatisticIdentifierRestriction',
    'ProductEyecatcherRestriction',
    'ProductCategoryRestriction',
  ],
  CoreFacet: [
    'GeoFacet',
    'MultiSelectFacet',
    'MultiSelectColorFacet',
    'RangeFacet',
    'SingleSelectFacet',
    'SortFacet',
  ],
  BaseValues: ['MultiSelectValues', 'MultiSelectColorValues'],
};
export default possibleTypes;
