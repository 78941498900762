import globalPageLoadEmitter from 'utils/PageLoadEmitter';
import { getFetchUri } from 'apollo/utils/fetchUtils';
import createTerminatingLink from 'apollo/links/createTerminatingLink';

const whiteListedRequests = [
  'contentPages',
  'search',
  'contentTemplate',
  'category',
  'login',
  'messages',
  'placeOrder',
  'placeReservation',
  'setAdditionalCartData',
];

export const fetchWithOperationName = (uri, options) => {
  const fetchUri = getFetchUri(uri, options);
  const promise = fetch(fetchUri, options);

  // return globalPageLoadEmitter to help handle certain async logic like scroll to hash
  if (whiteListedRequests.some((request) => fetchUri.includes(request))) {
    globalPageLoadEmitter.addPromise(promise);
  }
  return promise;
};

const createCSRTerminatingLink = createTerminatingLink(fetchWithOperationName);

export default createCSRTerminatingLink;
