import React, { useEffect, useMemo, useState, type FC, type ReactNode } from 'react';
import DynamicContentContext from 'Providers/DynamicContentContext';
import type {
  ContentSlot,
  DynamicContentDebug,
  DynamicContentServiceInterface,
} from 'services/DynamicContentService/DynamicContentService';

interface DynamicContextProviderProps {
  dynamicContentService: DynamicContentServiceInterface;
  children: ReactNode;
}

const DynamicContentProvider: FC<DynamicContextProviderProps> = ({
  dynamicContentService,
  children,
}) => {
  const [dismissedSlots, setDismissedSlots] = useState<string[]>(
    dynamicContentService.dismissedSlots,
  );
  const [slots, setSlots] = useState<ContentSlot[]>([]);
  const [debug, setDebug] = useState<DynamicContentDebug[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const updateHandler = (({ detail }: CustomEvent) => {
      setSlots(detail.contentSlots);
      setDebug(detail.debug);
      setLoading(false);
    }) as EventListener;

    dynamicContentService.addEventListener('updateSlots', updateHandler);
    return () => {
      dynamicContentService.removeEventListener('updateSlots', updateHandler);
    };
  }, [dynamicContentService]);

  useEffect(() => {
    const loadingHandler = () => {
      setSlots([]);
      setDebug([]);
      setLoading(true);
    };

    dynamicContentService.addEventListener('loading', loadingHandler);

    return () => {
      dynamicContentService.removeEventListener('loading', loadingHandler);
    };
  });

  const context = useMemo(
    () => ({
      debug,
      dismissedSlots,
      dismissSlot: (id: string) => {
        setDismissedSlots(dynamicContentService.dismissSlot(id));
      },
      loading,
      reloadContent: () => dynamicContentService.reloadContent(),
      slots,
    }),
    [debug, dismissedSlots, dynamicContentService, loading, slots],
  );

  return (
    <DynamicContentContext.Provider value={context}>{children}</DynamicContentContext.Provider>
  );
};

export default DynamicContentProvider;
