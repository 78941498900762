import { extend } from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/de-at';
import 'dayjs/locale/sv';
import 'dayjs/locale/sr';
import 'dayjs/locale/pl';
import 'dayjs/locale/cs';
import 'dayjs/locale/hr';
import 'dayjs/locale/hu';
import 'dayjs/locale/ro';
import 'dayjs/locale/sl';
import 'dayjs/locale/sk';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import UpdateLocale from 'dayjs/plugin/updateLocale';
import TimeZone from 'dayjs/plugin/timezone';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';

extend(UpdateLocale);
extend(customParseFormat);
extend(LocalizedFormat);
extend(TimeZone);
extend(utc);
extend(duration);
