export const routesRS = {
  brochuresPage: {
    root: '/(prospekti|brochures)/:brochureTypeId?',
    name: 'brochures',
  },
  cart: '/korpa',
  category: {
    name: 'kategorije',
    root: '/(kategorije|categories)/root',
  },
  contactcenter: {
    name: 'kontaktcentar',
    root: '/kontaktcentar',
    confirmation: 'hvala',
  },
  customerCardInfo: {
    name: '/c/lesnina-xxxl-kartica',
  },
  orderInformationPage: {
    name: 'statusnaloga',
    root: '/(orderStatus|statusnaloga)',
    result: 'order',
    cancel: 'cancel',
  },
  reservationPage: {
    name: 'rezervacija',
    form: 'rezervacija/form',
    root: '/(rezervacija|reservation)/:step?',
    confirmation: '/rezervacija/confirmation',
  },
  searchPage: {
    brands: 'brendovi',
    root: ['/(p/|search|s)', '/brendovi/:brandUrlCode'],
  },
  storeFinder: '/prodajnicentar/novi-sad/Lesnina_NS',
  storeOverview: {
    breadcrumbLink: '/lokacije',
    name: 'storeoverview',
    root: '/(storeoverview|lokacije)',
  },
  subsidiaryPage: {
    root: '/(prodajnicentar|subsidiary)/:SEOSlug/:subsidiaryId',
    name: 'prodajnicentar',
  },
  timendoPage: {
    name: 'terminzasavetovanje',
    root: '/(terminzasavetovanje|appointment)',
  },
};
