import { ApolloLink, FetchResult, Operation } from '@apollo/client';
import { tracking } from 'utils/tracking/tracking';
import { are3rdPartyScriptsAllowed } from 'hooks/use3rdPartyScriptsAllowed';
import createObjectFromString, { type DataObject } from 'apollo/links/trackingMiddlewareHelper';
import trackingMiddlewareLinkTEMPWEBROCKETFIX from './trackingMiddlewareLinkTEMPWEBROCKETFIX';
import { allowedOperationsToTrack } from './trackingMiddlewareAllowList';

const trackAPIData = async (
  operation: Operation,
  response: FetchResult<Record<string, any>, Record<string, any>, Record<string, any>>,
) => {
  const allowedOperation = allowedOperationsToTrack[operation.operationName];
  const allowedToTrack =
    !!allowedOperation && are3rdPartyScriptsAllowed(document.location.search.toString());

  if (!allowedToTrack) {
    return;
  }

  const trackingData: {
    requestUrl: {
      operationName: string;
      variables: DataObject;
    };
    response: DataObject;
  } = {
    requestUrl: {
      operationName: operation.operationName,
      variables: {},
    },
    response: {},
  };

  if (allowedOperation.request && operation.variables) {
    await Promise.all(
      allowedOperation.request.map(async (keyString) => {
        trackingData.requestUrl.variables = await createObjectFromString(
          keyString,
          operation.variables,
          trackingData.requestUrl.variables,
        );
      }),
    );
  }

  if (allowedOperation.response && response) {
    await Promise.all(
      allowedOperation.response.map(async (keyString) => {
        trackingData.response = await createObjectFromString(
          keyString,
          response,
          trackingData.response,
        );

        // Hardcoded fixes for Webrocket. THIS MUST BE REMOVED ASAP!
        trackingData.response = trackingMiddlewareLinkTEMPWEBROCKETFIX(
          operation.operationName,
          trackingData.response,
        );
      }),
    );
  }

  tracking({ type: 'api' }, 'response', trackingData);
};

const trackingMiddlewareLink = new ApolloLink((operation, forward) =>
  forward(operation).map((response) => {
    trackAPIData(operation, response);
    return response;
  }),
);

export default trackingMiddlewareLink;
