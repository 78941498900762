export const routesSE = {
  brochuresPage: {
    root: '/(reklamblad|brochures)/:brochureTypeId?',
    name: 'brochures',
  },
  category: {
    name: 'kategori',
    root: '/(kategori|categories)/root',
  },
  contactcenter: {
    name: 'kontaktcenter',
    root: '/(kontaktcenter|contactcenter)',
    confirmation: 'tack',
  },
  customerCardInfo: {
    name: '/c/prispass',
  },
  orderInformationPage: {
    name: 'orderstatus',
    root: '/(orderStatus|orderstatus)',
    result: 'order',
    cancel: 'cancel',
  },
  reservationPage: {
    name: 'bokning',
    form: 'bokning/form',
    root: '/(bokning|reservation)/:step?',
    confirmation: '/bokning/confirmation',
  },
  searchPage: {
    brands: 'maerken',
    root: ['/(p/|search|s)', '/maerken/:brandUrlCode'],
  },
  storeFinder: '/varuhus/xxxlutz-malmoe-bulltofta/SN',
  storeOverview: {
    breadcrumbLink: '/varuhus',
    name: 'storeoverview',
    root: '/(storeoverview|varuhus)',
  },
  subsidiaryPage: {
    root: '/(varuhus|subsidiary)/:SEOSlug/:subsidiaryId',
    name: 'varuhus',
  },
  timendoPage: {
    name: 'radgivningstid',
    root: '/(radgivningstid|appointment)',
  },
};
