export const routesDE = {
  customerCardInfo: {
    name: '/c/freundschaftskarte',
  },
  customerCardPage: {
    name: 'customercard',
    root: '/(customercard|kundenkarte)',
    register: 'registrieren',
    almostDone: 'fast-geschafft',
    confirmation: 'geschafft',
  },
  kitchenShowroomDetail: '/kuechenstudio/:SEOSlug/:subsidiaryId',
  kitchenStudioOverview: '/kuechenstudios',
};
