import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { sha256 } from 'crypto-hash';

const persistedQueryLink = (useGETForHashedQueries) =>
  createPersistedQueryLink({
    sha256,
    useGETForHashedQueries,
  });

export default persistedQueryLink;
