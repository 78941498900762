export const routesSI = {
  brochuresPage: {
    root: '/(prospekti|brochures)/:brochureTypeId?',
    name: 'brochures',
  },
  category: {
    name: 'kategorije',
    root: '/(kategorije|categories)/root',
  },
  contactcenter: {
    name: 'kontakt',
    root: '/(kontakt|contactcenter)',
    confirmation: 'hvala',
  },
  customerCardInfo: {
    name: '/c/xxxl-kartica-ugodnosti',
  },
  orderInformationPage: {
    name: 'statusnarocila',
    root: '/(orderStatus|statusnarocila)',
    result: 'order',
    cancel: 'cancel',
  },
  reservationPage: {
    confirmation: '/rezervacija/confirmation',
    form: 'rezervacija/form',
    name: 'rezervacija',
    root: '/(rezervacija|reservation)/:step?',
  },
  searchPage: {
    brands: 'znamke',
    root: ['/(p/|search|s)', '/znamke/:brandUrlCode'],
  },
  storeFinder: '/c/poslovalnice',
  storeOverview: {
    breadcrumbLink: '/poslovalnice',
    name: 'storeoverview',
    root: '/(storeoverview|poslovalnice)',
  },
  subsidiaryPage: {
    root: '/(poslovalnica|subsidiary)/:SEOSlug/:subsidiaryId',
    name: 'poslovalnica',
  },
  timendoPage: {
    name: 'terminsvetovanja',
    root: '/(terminsvetovanja|appointment)',
  },
};
