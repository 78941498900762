type RequestResponsePair = {
  request?: string[];
  response?: string[];
};

type AllowedOperationsToTrack = {
  [key: string]: RequestResponsePair;
};

export const allowedOperationsToTrack: AllowedOperationsToTrack = {
  addAddressData: {
    response: ['data.addAddressData[].baddress', 'data.addAddressData[].saddress'],
  },
  addBonuscard: { request: ['data.email'], response: ['data.addBonuscard'] },
  addCartEntry: {
    request: ['configuredId', 'code'],
    response: ['data.addCartEntry.orderType', 'data.addCartEntry.__typename'],
  },
  applyVoucher: {
    response: ['errors[].extensions.errors[].message', 'errors[].extensions.errors[].messageKey'],
  },
  appointmentStores: {
    response: [
      'data.getAppointmentStores.pointOfServices[].name',
      'data.getAppointmentStores.__typename',
    ],
  },
  bookAppointment: { response: ['data.bookAppointment'] },
  campaigns: { response: ['data.getFHCampaigns.campaigns', 'data.getFHCampaigns.__typename'] },
  category: {
    response: [
      'data.getCategory.breadcrumbs[].itemCode',
      'data.getCategory.breadcrumbs[].name',
      'data.getCategory.code',
      'data.getCategory.name',
      'data.getCategory.__typename',
    ],
  },
  changePassword: { response: ['data.changePassword'] },
  contentPages: {
    response: [
      'data.getContentPage.cmsPageType.code',
      'data.getContentPage.cmsPageType.cmsPageTypeSubtype',
      'data.getContentPage.code',
      'data.getContentPage.name',
      'data.getContentPage.__typename',
    ],
  },
  createExpressPaymentSession: {},
  createSavedInvoice: {},
  deleteAddressData: {},
  deleteSavedCreditCard: {},
  deleteSavedInvoice: {},
  getAddressValidation: {
    response: ['data.getAddressValidation.exactMatch', 'data.getAddressValidation.__typename'],
  },
  getAppointmentAreas: {
    response: [
      'data.getAppointmentAreas.areas[].publicName',
      'data.getAppointmentAreas.areas[].length',
      'data.getAppointmentAreas.areas[].__typename',
      'data.getAppointmentAreas.__typename',
    ],
  },
  getAppointmentInformation: {
    request: ['areaId'],
    response: [
      'data.getAppointmentInformation.areaInformation.name',
      'data.getAppointmentInformation.areaInformation.__typename',
      'data.getAppointmentInformation.start',
      'data.getAppointmentInformation.__typename',
    ],
  },
  getCarts: {
    request: ['type'],
    response: [
      'data.getCarts.carts[].appliedOrderPromotions[].id',
      'data.getCarts.carts[].appliedOrderPromotions[].name',
      'data.getCarts.carts[].appliedOrderPromotions[].promotion.code',
      'data.getCarts.carts[].appliedOrderPromotions[].promotionDiscount.value',
      'data.getCarts.carts[].appliedProductPromotions[].consumedEntries[].orderEntryNumber',
      'data.getCarts.carts[].appliedProductPromotions[].consumedEntries[].reductionValue',
      'data.getCarts.carts[].appliedProductPromotions[].id',
      'data.getCarts.carts[].appliedProductPromotions[].name',
      'data.getCarts.carts[].appliedProductPromotions[].promotion.code',
      'data.getCarts.carts[].appliedProductPromotions[].usedCouponCodes[]',
      'data.getCarts.carts[].appliedVoucherDataList[].applied',
      'data.getCarts.carts[].appliedVoucherDataList[].appliedValue.value',
      'data.getCarts.carts[].appliedVoucherDataList[].code',
      'data.getCarts.carts[].appliedVoucherDataList[].id',
      'data.getCarts.carts[].appliedVoucherDataList[].name',
      'data.getCarts.carts[].appliedVoucherDataList[].value',
      'data.getCarts.carts[].appliedVoucherDataList[].voucherType',
      'data.getCarts.carts[].code',
      'data.getCarts.carts[].deliveryCost.value',
      'data.getCarts.carts[].deliveryCostAssembling.value',
      'data.getCarts.carts[].entries[].deliveryTime',
      'data.getCarts.carts[].entries[].entryNumber',
      'data.getCarts.carts[].entries[].pointOfService.code',
      'data.getCarts.carts[].entries[].pointOfService.name',
      'data.getCarts.carts[].entries[].product.baseProduct',
      'data.getCarts.carts[].entries[].product.code',
      'data.getCarts.carts[].entries[].product.configuredProduct',
      'data.getCarts.carts[].entries[].product.deliveryCost.value',
      'data.getCarts.carts[].entries[].product.deliveryCost.__typename',
      'data.getCarts.carts[].entries[].product.priceData.currentPrice.value',
      'data.getCarts.carts[].entries[].product.reservationData.reservable',
      'data.getCarts.carts[].entries[].product.reservationData.__typename',
      'data.getCarts.carts[].entries[].product.__typename',
      'data.getCarts.carts[].entries[].quantity',
      'data.getCarts.carts[].entries[].selectedDeliveryMode',
      'data.getCarts.carts[].entries[].totalPrice.value',
      'data.getCarts.carts[].entries[].totalPrice.__typename',
      'data.getCarts.carts[].entries[].unitBasedCalculation.packageAmount',
      'data.getCarts.carts[].entries[].__typename',
      'data.getCarts.carts[].orderType',
      'data.getCarts.carts[].paymentMode',
      'data.getCarts.carts[].subTotal.value',
      'data.getCarts.carts[].totalItems',
      'data.getCarts.carts[].totalPrice.value',
      'data.getCarts.carts[].totalPriceWithTax.value',
      'data.getCarts.carts[].totalTax.value',
      'data.getCarts.carts[].userComment',
      'data.getCarts.carts[].__typename',
      'data.getCarts.__typename',
    ],
  },
  getCurrentUser: {
    response: [
      'data.getCurrentUser.anonymous',
      'data.getCurrentUser.uid',
      'data.getCurrentUser.__typename',
    ],
  },
  getCustomer: {
    response: [
      'data.getCustomer.email.description',
      'data.getCustomer.id',
      'data.getCustomer.__typename',
    ],
  },
  getCustomerCard: {
    response: ['data.getCustomerCard.confirmed', 'data.getCustomerCard.__typename'],
  },
  getFaqSuggest: {
    request: ['term'],
    response: ['data.getFaqSuggest.count', 'data.getFaqSuggest.__typename'],
  },
  getOrder: {
    request: ['code'],
    response: [
      'data.getOrder.appliedOrderPromotions[].id',
      'data.getOrder.appliedOrderPromotions[].name',
      'data.getOrder.appliedOrderPromotions[].promotion.code',
      'data.getOrder.appliedOrderPromotions[].promotionDiscount.value',
      'data.getOrder.appliedProductPromotions[].consumedEntries[].orderEntryNumber',
      'data.getOrder.appliedProductPromotions[].consumedEntries[].reductionValue',
      'data.getOrder.appliedProductPromotions[].id',
      'data.getOrder.appliedProductPromotions[].name',
      'data.getOrder.appliedProductPromotions[].promotion.code',
      'data.getOrder.appliedProductPromotions[].usedCouponCodes[]',
      'data.getOrder.appliedVoucherDataList[].applied',
      'data.getOrder.appliedVoucherDataList[].appliedValue.value',
      'data.getOrder.appliedVoucherDataList[].code',
      'data.getOrder.appliedVoucherDataList[].id',
      'data.getOrder.appliedVoucherDataList[].name',
      'data.getOrder.appliedVoucherDataList[].value',
      'data.getOrder.appliedVoucherDataList[].voucherType',
      'data.getOrder.code',
      'data.getOrder.deliveryCost.__typename',
      'data.getOrder.deliveryCost.value',
      'data.getOrder.deliveryCostAssembling.__typename',
      'data.getOrder.deliveryCostAssembling.value',
      'data.getOrder.entries[].entryNumber',
      'data.getOrder.entries[].product.__typename',
      'data.getOrder.entries[].product.baseProduct',
      'data.getOrder.entries[].product.code',
      'data.getOrder.entries[].product.configuredProduct',
      'data.getOrder.entries[].product.deliveryCost.value',
      'data.getOrder.entries[].product.deliveryCost.__typename',
      'data.getOrder.entries[].product.priceData.currentPrice.value',
      'data.getOrder.entries[].product.reservationData.reservable',
      'data.getOrder.entries[].product.reservationData.__typename',
      'data.getOrder.entries[].quantity',
      'data.getOrder.entries[].selectedDeliveryMode',
      'data.getOrder.entries[].unitBasedCalculation.packageAmount',
      'data.getOrder.entries[].unitBasedCalculation.__typename',
      'data.getOrder.orderType',
      'data.getOrder.paymentMode.code',
      'data.getOrder.paymentMode.__typename',
      'data.getOrder.selectedStore',
      'data.getOrder.subOrders[].selectedStore',
      'data.getOrder.subOrders[].totalPriceWithTax.value',
      'data.getOrder.totalItems',
      'data.getOrder.totalPrice.value',
      'data.getOrder.totalPrice.__typename',
      'data.getOrder.totalPriceWithTax.value',
      'data.getOrder.totalPriceWithTax.__typename',
      'data.getOrder.totalTax.value',
      'data.getOrder.totalTax.__typename',
      'data.getOrder.unitBasedCalculation.packageAmount',
      'data.getOrder.unitBasedCalculation.__typename',
      'data.getOrder.__typename',
    ],
  },
  getOrderContainer: {
    request: ['orderNumber'],
    response: [
      'data.getOrderContainer.orders[].orderNumber',
      'data.getOrderContainer.__typename',
      'errors[].message',
    ],
  },
  getOrderStatus: {
    response: ['data.getOrderStatus.referenceNumber', 'data.getOrderStatus.__typename'],
  },
  getZipCode: {
    request: ['lat', 'lng'],
    response: ['data.getZipCode.zipCode.value', 'data.getZipCode.__typename'],
  },
  logout: { response: ['data.logout'] },
  navigation: {
    response: [
      'data.navigation.data.main[].level',
      'data.navigation.data.main[].link',
      'data.navigation.__typename',
    ],
  },
  onlineSchedulingGetOrder: {
    response: ['data.onlineSchedulingGetOrder.zipCode', 'data.onlineSchedulingGetOrder.__typename'],
  },
  onlineSchedulingReservationAppointment: {
    response: ['data.onlineSchedulingReservationAppointment'],
  },
  placeOrder: {},
  placeReservation: {
    response: [
      'data.placeReservation.code',
      'data.placeReservation.placeReservation',
      'data.placeReservation.selectedStore',
      'data.placeReservation.totalPriceWithTax',
      'data.placeReservation.__typename',
    ],
  },
  postAppointment: {
    request: ['data.customer.email'],
    response: ['data.AppointmentResponse.appointmentId', 'data.AppointmentResponse.__typename'],
  },
  product: {
    response: [
      'data.getProduct.attributes',
      'data.getProduct.augmentedReality',
      'data.getProduct.availabilityStatus',
      'data.getProduct.baseProduct',
      'data.getProduct.brands',
      'data.getProduct.breadcrumbs[].itemCode',
      'data.getProduct.breadcrumbs[].name',
      'data.getProduct.code',
      'data.getProduct.color',
      'data.getProduct.deliveryData',
      'data.getProduct.deliveryTime',
      'data.getProduct.eyecatchers',
      'data.getProduct.mediaData',
      'data.getProduct.name',
      'data.getProduct.onlineOnly',
      'data.getProduct.priceData',
      'data.getProduct.ratingData',
      'data.getProduct.reservationData.reservable',
      'data.getProduct.statisticKPI',
      'data.getProduct.style',
      'data.getProduct.__typename',
    ],
  },
  productAvailability: { request: ['zipCode'] },
  productConfiguratorInit: { request: ['productCode'] },
  productConfiguratorModify: { request: ['productCode'] },
  receiveContentSlots: {
    response: [
      'data.receiveContentSlots.contentslots[].code',
      'data.receiveContentSlots.contentslots[].components[].id',
      'data.receiveContentSlots.contentslots[].components[].slot',
      'data.receiveContentSlots.contentslots[].components[].subtext',
      'data.receiveContentSlots.contentslots[].components[].text',
      'data.receiveContentSlots.contentslots[].components[].tracking.c_id',
      'data.receiveContentSlots.contentslots[].components[].tracking.campaign_name',
      'data.receiveContentSlots.contentslots[].components[].tracking.layer_name',
      'data.receiveContentSlots.contentslots[].components[].tracking.mid',
      'data.receiveContentSlots.__typename',
    ],
  },
  registerUser: { response: ['data.registerUser.email'] },
  registerUserConfirmation: {
    response: [
      'data.registerUserConfirmation.email',
      'data.registerUserConfirmation.__typename',
      'errors[].message',
    ],
  },
  resetPassword: { response: ['data.resetPassword'] },
  resetPasswordInquiry: { response: ['data.resetPasswordInquiry'] },
  search: {
    response: [
      'data.getSearchBasics.searchResults[].code',
      'data.getSearchBasics.searchResults[].id',
      'data.getSearchBasics.__typename',
    ],
  },
  subscribeAnonymousNewsletterCDM: {
    request: ['data.email.description'],
    response: ['data.subscribeAnonymousNewsletterCDM'],
  },
  unitBasedCalculation: {
    response: [
      'data.getUnitBasedCalculation.packageAmount',
      'data.getUnitBasedCalculation.__typename',
    ],
  },
  updateAddressData: { request: ['addressData.baddress', 'addressData.saddress'] },
  updateCartAddresses: {
    request: ['data.addressesDifferent', 'data.saveAddresses'],
    response: [
      'data.updateCartAddresses.deliveryAddress.email',
      'data.updateCartAddresses.paymentAddress.country.name',
      'data.updateCartAddresses.__typename',
    ],
  },
  updateCustomerCard: {
    response: ['data.updateCustomerCard.confirmed', 'data.updateCustomerCard.__typename'],
  },
  updateCustomerData: {},
  zipCode: { response: ['data.suggestZipCode.postalcodes', 'data.suggestZipCode.__typename'] },
};
