import i18next from 'i18next';
import ICU from 'i18next-icu';

const initI18n = async (languageCode: string, isSmarteditEnabled = false) => {
  const instance = i18next.createInstance();

  instance.use(ICU);

  await instance.init({
    debug: false,
    // allow keys to be phrases having `:`, `.`
    nsSeparator: false,
    keySeparator: false,
    lng: languageCode,
    i18nFormat: {
      memoize: !isSmarteditEnabled,
    },
    compatibilityJSON: 'v3',
    interpolation: {
      skipOnVariables: false,
    },
    returnNull: true,
    // displays no messageKey if messages not loaded yet
    // parseMissingKeyHandler: (key) => (CONFIG.IS_PROD ? null : key),
  });

  return instance;
};

export default initI18n;
