import { ApolloClient } from '@apollo/client';
import { baseClientOptions } from 'apollo/config';
import createCache from 'apollo/cache';
import createApolloClientLink from './createApolloClientLink';

const createCSRClient = (apiUrl, languageCode, logger) =>
  new ApolloClient({
    ...baseClientOptions,
    cache: createCache(),
    link: createApolloClientLink(apiUrl, languageCode, logger),
  });

export default createCSRClient;
