export const routesPL = {
  brochuresPage: {
    root: '/(prospekty|broszury)/:brochureTypeId?',
    name: 'broszury',
  },
  cart: '/koszyk',
  category: {
    name: 'kategorie',
    root: '/(kategorie|categories)/root',
  },
  contactcenter: {
    name: 'centrumkontaktowe',
    root: '/(centrumkontaktowe|contactcenter)',
    confirmation: 'dziekujemy',
  },
  orderInformationPage: {
    name: 'statuszlecenia',
    root: '/(orderStatus|statuszlecenia)',
    result: 'order',
    cancel: 'cancel',
  },
  reservationPage: {
    name: 'rezerwacja',
    form: 'rezerwacja/form',
    root: '/(rezerwacja|reservation)/:step?',
    confirmation: '/rezerwacja/confirmation',
  },
  searchPage: {
    brands: 'marki',
    root: ['/(p/|search|s)', '/marki/:brandUrlCode'],
  },
  subsidiaryPage: {
    root: '/(sklep|subsidiary)/:SEOSlug/:subsidiaryId',
    name: 'sklep',
  },
  timendoPage: {
    name: 'terminkonsultacji',
    root: '/(terminkonsultacji|appointment)',
  },
};
