import React from 'react';
import { parseHostFromEnvironment } from 'utils/hostParser';
import getCountryContextData from 'Providers/IntlProvider/languageLookup';
import getPages from 'pages/App/pages';
import withFeatureFlag from 'hoc/withFeatureFlag';
import { Route } from 'react-router-dom';
import PageLoadEmitter from 'utils/PageLoadEmitter';
import { Loading } from '@xxxlgroup/hydra-ui-components';

const getPagesForRoutes = (countryRoutes) => {
  const pages = getPages(countryRoutes);
  return pages.map(({ component: PageComponent, featureFlag, ...page }) => {
    const Page = (props) => <PageComponent {...props} fallback={<Loading />} />;

    const FeatureFlagComponent = featureFlag ? withFeatureFlag(Page, featureFlag) : Page;
    const component = (props) => {
      PageLoadEmitter.addLoadable(PageComponent);
      return <FeatureFlagComponent {...props} />;
    };
    const key = typeof page.path === 'string' ? page.path : page.path[0];

    return <Route {...page} key={key} component={component} />;
  });
};

const createIntlDataContext = (host) => {
  const parsedHostObject = parseHostFromEnvironment(host);

  const isDynamicCountry = CONFIG.IS_PROD || parsedHostObject.env === 'y';
  const countryCode = (isDynamicCountry && parsedHostObject.tld) || CONFIG.COUNTRY;

  if (!countryCode) {
    // eslint-disable-next-line no-console
    console.error(
      `createIntlDataContext: Cannot parse country code from host ${host} and default country ${CONFIG.COUNTRY}`,
    );
  }

  const countryContextData = getCountryContextData(countryCode);
  countryContextData.pages = getPagesForRoutes(countryContextData.routes);
  countryContextData.env = parsedHostObject.env;

  return countryContextData;
};

export default createIntlDataContext;
