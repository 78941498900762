import { FetchResult } from '@apollo/client';
import { sha256 } from 'crypto-hash';
import { getProperty, setProperty } from 'dot-prop';

export type DataObject = FetchResult<Record<string, any>, Record<string, any>, Record<string, any>>;

const emptyArraySelector = /\[\]/;
const trailingArraySelector = /\[\d*\]$/;
const emailRegex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;

const primitiveTypes = ['string', 'number', 'boolean', 'null', 'undefined'];
const isPrimitivesArray = (array: unknown[]) =>
  array.every((entry) => entry === null || primitiveTypes.includes(typeof entry));

const addIndexesToPath = (path: string, response: DataObject) => {
  // immediate return if no array notation is found in current path
  if (!emptyArraySelector.test(path)) {
    return [path];
  }

  const parts = path.split('[]');

  const pathsWithIndexes: string[] = [];

  const arrayToCheck = getProperty(response, parts[0]);
  let amountOfNeededIndexes = 0;

  if (Array.isArray(arrayToCheck)) {
    amountOfNeededIndexes = (arrayToCheck as []).length;

    if (amountOfNeededIndexes < 1) {
      // the data is an empty array. This should end up in the final result as well
      pathsWithIndexes.push(parts[0]);
    } else if (parts.length === 2 && parts[1] === '' && !isPrimitivesArray(arrayToCheck)) {
      // This is a trailing(!) array selector and arrayToCheck does not only contain primitives. So
      // remove the array accessor from the end
      parts.pop();
      parts[0].replace(trailingArraySelector, '');
      pathsWithIndexes.push(parts[0]);
      amountOfNeededIndexes = 0;
    }
  } else if (arrayToCheck === undefined) {
    // no data is undefined, but we still want undefined to be in the final result
    pathsWithIndexes.push(parts[0]);
  }

  const remainingPathParts = parts.slice(1, parts.length).join('[]');

  for (let i = 0; i < amountOfNeededIndexes; i += 1) {
    const pathWithIndex = `${parts[0]}[${i}]${remainingPathParts}`;
    const recursedPaths = addIndexesToPath(pathWithIndex, response);

    recursedPaths.forEach((recursedPath) => {
      pathsWithIndexes.push(recursedPath);
    });
  }

  return pathsWithIndexes;
};

const collectAndBuild = async (path: string, response: DataObject, resultingObject: DataObject) => {
  const correctedPath = path;

  let objectValue: unknown = getProperty(response, correctedPath);

  if (Array.isArray(objectValue)) {
    if (isPrimitivesArray(objectValue)) {
      await Promise.all(
        objectValue.map(async (arrayEntry, index) => {
          let valueToSetInTheArray = arrayEntry;

          if (typeof arrayEntry === 'string' && emailRegex.test(arrayEntry)) {
            valueToSetInTheArray = await sha256(arrayEntry);
          }

          (objectValue as string[])[index] = valueToSetInTheArray;
          return valueToSetInTheArray;
        }),
      );
    } else {
      objectValue = objectValue.length > 0;
    }
  } else if (typeof objectValue === 'object' && objectValue !== null) {
    objectValue = Object.keys(objectValue).length > 0;
  } else if (typeof objectValue === 'string' && emailRegex.test(objectValue)) {
    objectValue = await sha256(objectValue);
  }

  setProperty(resultingObject, correctedPath, objectValue);

  return resultingObject;
};

const createObjectFromString = async (
  path: string,
  response: DataObject,
  resultingObject: DataObject,
) => {
  // immediate return if no array notation is found in current path
  if (!emptyArraySelector.test(path)) {
    return collectAndBuild(path, response, resultingObject);
  }

  let newObject = resultingObject;

  const pathsWithIndexes = addIndexesToPath(path, response);

  await Promise.all(
    pathsWithIndexes.map(async (pathWithIndexes) => {
      newObject = await collectAndBuild(pathWithIndexes, response, newObject);
      return pathWithIndexes;
    }),
  );

  return newObject;
};

export default createObjectFromString;
