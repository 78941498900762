import dayjs from 'dayjs';
import { languageLookupTable } from './lookupTables';

const cloneInstanceWithLocale = (language, instance) => {
  const locale = language?.locale || 'de';
  instance.locale(locale);

  // In case we need to overwrite locale formatting strings, it can be defined in lookupTables.js
  if (language.dayjsLocale) {
    dayjs.updateLocale(locale, language.dayjsLocale);
  }
};

export const countryContextData = (languageCode, rail, brand) => {
  if (!languageLookupTable[languageCode]) {
    // eslint-disable-next-line no-console
    console.error(
      `getCountryContextData: Cannot find language lookup table for language ${languageCode} with rail ${rail}`,
    );
  }
  const language = {
    rail,
    brand,
    languageCode,
    ...languageLookupTable[languageCode],
  };

  const timeZone = languageLookupTable[languageCode]?.timeZone || 'Europe/Vienna';

  cloneInstanceWithLocale(language, dayjs);

  return {
    language,
    dayjs: (...args) => dayjs(...args),
    dayjsUtc: (...args) => dayjs.utc(...args),
    dayjsTimeZone: (...args) => dayjs(...args).tz(timeZone),
  };
};
