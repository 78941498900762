import browserConfig from 'assets/images/icons/browserconfig.xml';
import favicon from 'assets/images/icons/favicon.ico';
import favicon16x16 from 'assets/images/icons/favicon-16x16.png';
import favicon32x32 from 'assets/images/icons/favicon-32x32.png';
import iconAppleTouchIcon180x180 from 'assets/images/icons/apple-touch-icon-180x180.png';
import iconSafariPinnedTab from 'assets/images/icons/safari-pinned-tab.svg';
import iconMsTile144x144 from 'assets/images/icons/mstile-144x144.png';
import iphone5Splash from 'assets/images/icons/splashscreens/iphone5_splash.png';
import iphone6Splash from 'assets/images/icons/splashscreens/iphone6_splash.png';
import iphoneplusSplash from 'assets/images/icons/splashscreens/iphoneplus_splash.png';
import iphonexSplash from 'assets/images/icons/splashscreens/iphonex_splash.png';
import iphonexrSplash from 'assets/images/icons/splashscreens/iphonexr_splash.png';
import iphonexsmaxSplash from 'assets/images/icons/splashscreens/iphonexsmax_splash.png';
import ipadSplash from 'assets/images/icons/splashscreens/ipad_splash.png';
import ipadpro1Splash from 'assets/images/icons/splashscreens/ipadpro1_splash.png';
import ipadpro3Splash from 'assets/images/icons/splashscreens/ipadpro3_splash.png';
import ipadpro2Splash from 'assets/images/icons/splashscreens/ipadpro2_splash.png';

const linkTags = (appColorHighlight, other) => [
  {
    rel: 'apple-touch-icon',
    sizes: '180x180',
    href: `${iconAppleTouchIcon180x180}`,
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '32x32',
    href: `${favicon32x32}`,
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '16x16',
    href: `${favicon16x16}`,
  },
  {
    rel: 'shortcut icon',
    href: `${favicon}`,
  },
  {
    rel: 'mask-icon',
    href: `${iconSafariPinnedTab.url}`,
    color: `${appColorHighlight}`,
  },
  {
    rel: 'apple-touch-startup-image',
    media: '(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2)',
    href: `${iphone5Splash}`,
  },
  {
    rel: 'apple-touch-startup-image',
    media: '(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)',
    href: `${iphone6Splash}`,
  },
  {
    rel: 'apple-touch-startup-image',
    media: '(device-width: 621px) and (device-height: 1104px) and (-webkit-device-pixel-ratio: 3)',
    href: `${iphoneplusSplash}`,
  },
  {
    rel: 'apple-touch-startup-image',
    media: '(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3)',
    href: `${iphonexSplash}`,
  },
  {
    rel: 'apple-touch-startup-image',
    media: '(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2)',
    href: `${iphonexrSplash}`,
  },
  {
    rel: 'apple-touch-startup-image',
    media: '(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3)',
    href: `${iphonexsmaxSplash}`,
  },
  {
    rel: 'apple-touch-startup-image',
    media: '(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2)',
    href: `${ipadSplash}`,
  },
  {
    rel: 'apple-touch-startup-image',
    media: '(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2)',
    href: `${ipadpro1Splash}`,
  },
  {
    rel: 'apple-touch-startup-image',
    media: '(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2)',
    href: `${ipadpro3Splash}`,
  },
  {
    rel: 'apple-touch-startup-image',
    media: '(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2)',
    href: `${ipadpro2Splash}`,
  },
  ...other,
];

const metaTags = (appColorHighlight, other) => [
  {
    name: 'msapplication-TileColor',
    content: `${appColorHighlight}`,
  },
  {
    name: 'msapplication-TileImage',
    content: `${iconMsTile144x144}`,
  },
  {
    name: 'msapplication-config',
    content: `${browserConfig}`,
  },
  ...other,
];

export { linkTags, metaTags };
