/* eslint-disable */
// @ts-nocheck

import { DataObject } from 'apollo/links/trackingMiddlewareHelper';

const trackingMiddlewareLinkTEMPWEBROCKETFIX = (operationName: string, response: DataObject) => {
  if (operationName === 'getOrder') {
    if (!response.data.getOrder.selectedStore) {
      response.data.getOrder.selectedStore = false;

      if (Array.isArray(response.data.getOrder.subOrders)) {
        response.data.getOrder.subOrders = response.data.getOrder.subOrders.map((entry) => {
          if (!entry.selectedStore) {
            entry.selectedStore = false;
          }
          return entry;
        });
      }
    }
  }
  if (operationName === 'placeReservation') {
    if (!response.data.placeReservation.selectedStore) {
      response.data.placeReservation.selectedStore = false;
    }
  }
  if (operationName === 'product') {
    if (!response.data.getProduct.deliveryTime) {
      response.data.getProduct.deliveryTime = false;
    }
    if (!response.data.getProduct.ratingData) {
      response.data.getProduct.ratingData = false;
    }
  }

  return response;
};

export default trackingMiddlewareLinkTEMPWEBROCKETFIX;
