export const routesHU = {
  brochuresPage: {
    root: '/(prospektusok|brochures)/:brochureTypeId?',
    name: 'brochures',
  },
  category: {
    name: 'kategoriak',
    root: '/(kategoriak|categories)/root',
  },
  contactcenter: {
    name: 'uegyfelszolgalat',
    root: '/(uegyfelszolgalat|contactcenter)',
    confirmation: 'koeszoenjuek',
  },
  customerCardInfo: {
    name: '/c/premium_card',
  },
  orderInformationPage: {
    name: 'szerzodesstatusz',
    root: '/(orderStatus|szerzodesstatusz)',
    result: 'order',
    cancel: 'cancel',
  },
  reservationPage: {
    name: 'reservation',
    form: 'reservation/form',
    root: '/(foglalas|reservation)/:step?',
    confirmation: '/reservation/confirmation',
  },
  searchPage: {
    brands: 'markak',
    root: ['/(p/|search|s)', '/markak/:brandUrlCode'],
  },
  storeFinder: '/c/aruhazak',
  storeOverview: {
    breadcrumbLink: '/aruhazaink_elerhetosege',
    name: 'storeoverview',
    root: '/(storeoverview|aruhazaink_elerhetosege)',
  },
  subsidiaryPage: {
    root: '/(aruhaz|subsidiary)/:SEOSlug/:subsidiaryId',
    name: 'aruhaz',
  },
  timendoPage: {
    name: 'idopont',
    root: '/(idopont|appointment)',
  },
};
