export const routesRO = {
  brochuresPage: {
    root: '/(prospecte|brochures)/:brochureTypeId?',
    name: 'brochures',
  },
  category: {
    name: 'categorii',
    root: '/(categorii|categories)/root',
  },
  contactcenter: {
    name: 'centrudecontact',
    root: '/(centrudecontact|contactcenter)',
    confirmation: 'multumim',
  },
  customerCardInfo: {
    name: '/c/card-premium-xxxl',
  },
  orderInformationPage: {
    name: 'statuscomanda',
    root: '/(orderStatus|statuscomanda)',
    result: 'order',
  },
  reservationPage: {
    name: 'rezervare',
    form: 'rezervare/form',
    root: '/(rezervare|reservation)/:step?',
    confirmation: '/rezervare/confirmation',
  },
  searchPage: {
    brands: 'marci',
    root: ['/(p/|search|s)', '/marci/:brandUrlCode'],
  },
  storeOverview: {
    breadcrumbLink: '/locatii',
    name: 'storeoverview',
    root: '/(storeoverview|locatii)',
  },
  subsidiaryPage: {
    root: '/(filiala|subsidiary)/:SEOSlug/:subsidiaryId',
    name: 'filiala',
  },
  timendoPage: {
    name: 'consultatie',
    root: '/(consultatie|appointment)',
  },
};
