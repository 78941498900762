import { InMemoryCache } from '@apollo/client';
import typePolicies from 'apollo/cache/typePolicies';
import possibleTypes from 'apollo/cache/possibleTypes';
import writeDefaultsToCache from 'apollo/cache/writeDefaultsToCache';

// code coverage will be added in XXXL-41224

const createCache = () => {
  const cache = new InMemoryCache({
    typePolicies,
    possibleTypes,
    freezeResults: true,
  }).restore(!CONFIG.IS_SSR && window.__APOLLO_STATE__);

  writeDefaultsToCache(cache);

  return cache;
};

export default createCache;
