export const routesAT = {
  customerCardInfo: {
    name: '/c/preisepass-vorteile',
  },
  customerCardPage: {
    name: 'customercard',
    root: '/(customercard|kundenkarte)',
    register: 'registrieren',
    almostDone: 'fast-geschafft',
    confirmation: 'geschafft',
  },
  kitchenStudioOverview: '/kuechenstudios',
  kitchenShowroomDetail: '/kuechenstudio/:SEOSlug/:subsidiaryId',
};
