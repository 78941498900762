import { ApolloLink, createHttpLink } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { fetchUpload } from 'apollo/utils/fetchUtils';

const linkOptions = {
  credentials: 'same-origin',
};

const createTerminatingLink =
  (fetchWithOperationName) =>
  (links, uri, headers, fetchOptions = {}) =>
    ApolloLink.from(links)
      .split(
        (operation) => operation.getContext().hasUpload || false,
        createUploadLink({
          ...linkOptions,
          fetchOptions,
          uri,
          headers: {
            ...headers,
            'Apollo-Require-Preflight': 'true',
          },
          fetch: fetchUpload,
        }),
      )
      .split(
        (operation) => operation.getContext().batch || false,
        new BatchHttpLink({
          ...linkOptions,
          fetchOptions,
          headers,
          uri,
          batchInterval: 10,
          batchMax: 20,
          fetch: fetchWithOperationName,
        }),
        createHttpLink({
          ...linkOptions,
          fetchOptions,
          uri,
          headers,
          fetch: fetchWithOperationName,
        }),
      );

export default createTerminatingLink;
