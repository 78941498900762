import { onError } from '@apollo/client/link/error';
import { fromPromise } from '@apollo/client';

const logError = ({ error, networkError, operation, response }, logger) => {
  logger({
    ...error,
    networkError,
    response,
    operation: {
      operationName: operation.operationName ? operation.operationName : null,
      variables: operation.variables ? operation.variables : null,
      extensions: operation.extensions ? operation.extensions : null,
    },
  });
};

/* istanbul ignore next */
const createErrorLink = (apiUrl, logger, errorContext = { hasGraphQLError: false }) =>
  onError(({ graphQLErrors, networkError, operation, forward, response }) => {
    Object.assign(errorContext, { hasGraphQLError: true });

    if (graphQLErrors) {
      /* eslint-disable */
      for (const error of graphQLErrors) {
        if (error.extensions && error.extensions.statusCode === 403) {
          return fromPromise(
            fetch(`${apiUrl}/csrf/token`).catch((e) => {
              logger.error('Cannot refresh token', e);
              if (!CONFIG.IS_SSR) {
                throw e;
              }
            }),
          ).flatMap(() => forward(operation));
        }
        logError({ error, networkError, operation, response }, logger);
      }
      /* eslint-disable */
    }
    if (networkError && !CONFIG.IS_PROD) {
      // eslint-disable-next-line no-console
      console.error(`[Network error]: ${networkError}, operation ${JSON.stringify(operation)}`);
    }
    return null;
  });

export default createErrorLink;
