import { setContext } from '@apollo/client/link/context';
import { getCookie } from 'utils/cookie';

const csrfLink = (apiUrl) =>
  setContext(async (_, { headers }) => {
    let token = getCookie('XSRF-TOKEN');

    if (token) {
      return {
        headers: {
          ...headers,
          'X-CSRF-TOKEN': token,
        },
      };
    }

    try {
      token = await fetch(`${apiUrl}/csrf/token`);
      return {
        headers: {
          ...headers,
          'X-CSRF-TOKEN': token,
        },
      };
    } catch (error) {
      if (!CONFIG.IS_SSR) {
        throw error;
      }

      return { headers };
    }
  });

export default csrfLink;
