import { searchBarClientFieldsPolicy } from 'components/Header/components/SearchBar/SearchBar.variables';
import { ratingsReviewsFieldsPolicy } from 'pages/ProductDetail/components/RatingsReviews/components/RatingsReviewsSection/RatingsReviewsSection.variables';

const typePolicies = {
  Query: {
    fields: {
      getMessagesWithCodes: {
        read: (existing, options) => {
          const { toReference, args } = options;
          const messagesRef = args.codes
            .map((code) =>
              options.cache.data.data[`Message:${code}`]
                ? toReference({
                    __typename: 'Message',
                    id: code,
                  })
                : false,
            )
            .filter((c) => c);
          const allInCache = messagesRef.length === args.codes.length;
          return allInCache ? messagesRef : existing;
        },
      },
      ...searchBarClientFieldsPolicy,
      ...ratingsReviewsFieldsPolicy,
      getProduct(existingData, { args, toReference }) {
        const reference = toReference({
          __typename: 'Product',
          id: args.productCode,
          code: args.productCode,
        });
        return existingData || reference;
      },
    },
  },
  Product: {
    keyFields: false,
  },
};

export default typePolicies;
