export const routesDefault = {
  allTranslations: '/allTranslations',
  brochuresPage: {
    root: '/(prospekte|brochures)/:brochureTypeId?',
    name: 'brochures',
  },
  cart: '/cart',
  category: {
    name: 'kategorien',
    root: '/(kategorien|categories)/root',
  },
  categoryPage: '/(.*)-:category([cC][0-9][cC0-9]*)',
  checkout: {
    name: 'checkout',
    root: '/checkout/:step?',
    additional: {
      name: 'additional',
      root: '/checkout/additional',
    },
    address: 'address',
    payment: 'payment',
    summary: 'summary',
    cancel: 'cancel',
    error: 'error',
    external: 'external',
  },
  cmsPage: '/c/:cmsPageCode',
  comingSoon: {
    root: '/',
    agb: '/c/agb',
    legalNotice: '/c/legal',
    optIn: '/c/newsletter-optinconfirm',
    optOut: '/c/newsletter-optout',
  },
  contactcenter: {
    name: 'kontaktcenter',
    root: '/(kontaktcenter|contactcenter)',
    confirmation: 'danke',
  },
  customerAccount: {
    name: 'account',
    root: '/(account|konto)',
    customerCard: {
      name: 'customerCard',
      root: '/account/customerCard',
    },
    userData: {
      name: 'userData',
      root: '/account/userData/:editId?',
    },
    addressBook: {
      name: 'addressbook',
      root: '/account/addressbook/:editId?',
    },
    purchases: {
      name: 'purchases',
      root: '/account/purchases',
    },
  },
  customerAuthentication: {
    resetPassword: {
      name: 'resetPassword',
      root: '/users/resetPassword/reset',
    },
    signUp: {
      name: 'signup',
      root: '/(signup|registrieren)',
    },
    confirmAccount: {
      root: '/register',
    },
    signIn: {
      name: 'signin',
      root: '/signin/:redirectTo?',
    },
  },
  customerCardInfo: {
    name: '/c/preisepass-vorteile',
  },
  customerCardPage: {
    name: 'customercard',
    root: '/(customercard|kundenkarte)',
    register: 'register',
    almostDone: 'almostDone',
    confirmation: '(confirmation|geschafft)',
  },
  evsPage: {
    name: 'emailverification',
    root: '/emailverification',
    error: 'error',
    success: 'success',
  },
  Faq: {
    name: 'faq',
    root: '/faq',
  },
  favouritesPage: {
    name: 'favourites',
    root: '/favourites',
  },
  homePage: '/',
  hotdealsSearchPage: {
    root: '/(hs)',
  },
  imageSearchPage: {
    name: 'is',
    root: '/(is)',
  },
  incidents: {
    name: 'incidents',
    root: '/(incidents)',
    incidentProcess: {
      name: 'incidentprocess',
      root: '/incidents/incidentprocess',
    },
    orderOverview: {
      name: 'orderOverview',
      root: '/incidents/orderOverview',
    },
  },
  kitchenAdvisory: '/kuechenberatung',
  kitchenAdvisoryConfirm: 'confirm',
  kitchenShowroomDetail: '/kitchen-studio/:SEOSlug/:subsidiaryId',
  kitchenStudioOverview: '/kitchen-studios',
  legal: {
    privacy: '/c/privacy',
  },
  newsletter: '/newsletter',
  orders: {
    name: 'orders',
    confirmation: '/orders/confirmation',
  },
  orderInformationPage: {
    name: 'orderStatus',
    root: '/(orderStatus|auftragsStatus)',
    result: 'order',
    cancel: 'cancel',
    returnLabel: 'return',
  },
  productDetailPageHotdeals: '/h/:productUrl',
  productDetailPage: '/p/:productUrl',
  ratingsOverviewPage: '/review',
  reservationPage: {
    name: 'reservation',
    form: 'reservation/form',
    root: '/(reservierung|reservation)/:step?',
    confirmation: '/reservation/confirmation',
    summary: '/reservation/summary',
  },
  restaurantDetail: '/restaurant/:SEOSlug/:subsidiaryId',
  searchPage: {
    brands: 'marken',
    root: ['/(p/|search|s)', '/marken/:brandUrlCode'],
  },
  selfPickupPage: '/selfpickup',
  sellerPage: '/partner/:SEOSlug/:sellerId',
  sellerPageId: '/partner/:sellerId',
  subsidiaryPage: {
    root: '/(filiale|subsidiary)/:SEOSlug/:subsidiaryId',
    name: 'filiale',
  },
  storeFinder: '/c/filialen-standorte',
  storeOverview: {
    breadcrumbLink: '/filialen-standorte',
    name: 'storeoverview',
    root: '/(storeoverview|filialen-standorte)',
  },
  timendoPage: {
    name: 'beratungstermin',
    root: '/(beratungstermin|appointment)',
  },
  transporter: {
    name: 'transporter',
    root: '/transporter',
    confirmation: '/transporter/confirmation',
  },
};
